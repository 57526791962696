import React from "react";

export const useAuth = () => {
  const [state, setState] = React.useState({
    isAuthenticated: false,
    session: null,
    loading: false,
    error: null,
  });
  const [credentials, setCredentials] = React.useState(undefined);
  const [trigger, setTrigger] = React.useState(false);
  const [triggerLogout, setTriggerLogout] = React.useState(false);

  const authenticate = async ({ username, password }) => {
    const response = await fetch(`${process.env.REACT_APP_API}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (!response.ok)
      throw new Error(`Request failed with status ${response.status}`);

    return await response.json();
  };

  const logout = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.ok;
  };

  const getSession = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/sessions`, {
      credentials: "include",
    });

    return response.ok;
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setState({
        isAuthenticated: false,
        session: null,
        loading: true,
        error: null,
      });

      try {
        const session = await getSession();

        if (session) {
          setState({
            isAuthenticated: true,
            session,
            loading: false,
            error: null,
          });
        } else {
          setState({
            isAuthenticated: false,
            session: null,
            loading: false,
            error: null,
          });
        }
      } catch (error) {
        setState({
          isAuthenticated: false,
          session: null,
          loading: false,
          error,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!credentials) return;

    const fetchData = async () => {
      setState({
        isAuthenticated: false,
        session: null,
        loading: true,
        error: null,
      });

      try {
        const session = await authenticate(credentials);

        if (session) {
          setState({
            isAuthenticated: true,
            session,
            loading: false,
            error: null,
          });
        } else {
          setState({
            isAuthenticated: false,
            session: null,
            loading: false,
            error: null,
          });
        }
      } catch (error) {
        setState({
          isAuthenticated: false,
          session: null,
          loading: false,
          error,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  React.useEffect(() => {
    if (!triggerLogout) return;

    const fetchData = async () => {
      try {
        const success = await logout();

        if (success) {
          setState({
            isAuthenticated: false,
            session: null,
            loading: false,
            error: null,
          });
          setTriggerLogout(false);
        }
      } catch (error) {
        setState({
          isAuthenticated: false,
          session: null,
          loading: false,
          error,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerLogout]);

  return {
    ...state,
    authenticate: (credentials) => {
      setCredentials(credentials);
      setTrigger(!trigger);
    },
    logout: () => setTriggerLogout(true),
  };
};
