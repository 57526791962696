import React from "react";
import { Navigate } from "react-router-dom";
import "./App.css";
import { Heading, RoutedTabGroup } from "./components";
import { useAuth } from "./hooks";
import { DataPage, LoginPage, UserPage } from "./pages";

const Logout = ({ logout }) => {
  logout();
  return <Navigate to="/" />;
};

const App = () => {
  const { isAuthenticated, session, loading, error, logout, authenticate } =
    useAuth();

  const tabs = [
    {
      name: "Dashboard",
      element: <p>TODO</p>,
      route: "dashboard",
    },
    {
      name: "Data",
      element: <DataPage {...{ session }} />,
      route: "data",
    },
  ];

  if (isAuthenticated && session && session.type === "ADMIN")
    tabs.push(
      {
        name: "Users",
        element: <UserPage />,
        route: "users",
      },
      {
        name: "Jobs",
        element: <p>TODO</p>,
        route: "jobs",
      }
    );

  if (isAuthenticated)
    tabs.push({
      name: "Logout",
      element: <Logout {...{ logout }} />,
      route: "/logout",
    });

  if (loading) return null;

  if (!isAuthenticated)
    return (
      <LoginPage
        {...{
          authenticate,
          error,
        }}
      />
    );

  return (
    <div className="App">
      <RoutedTabGroup
        variant={window.innerWidth < 1000 ? "horizontal" : "vertical"}
        minimizable={true}
        tabHeader={<Heading variant="logo">VASANDANI</Heading>}
        tabs={tabs}
        style={{
          minHeight: "100vh",
        }}
      />
    </div>
  );
};

export default App;
