import React from "react";
import { useSearchParams } from "react-router-dom";

import "./DataPage.css";
import {
  Array,
  Button,
  Dropdown,
  Heading,
  Input,
  TabGroup,
} from "../../components";
import { useAPI, useFetch } from "../../hooks";
import { DocumentPage, SchemaPage } from "..";

const NoSchema = ({ postSchema, reset, session }) => {
  const [name, setName] = React.useState("");

  return (
    <div
      style={{
        padding: "var(--padding)",
      }}
    >
      <Heading>It looks like there's nothing here...</Heading>
      {session.type === "ADMIN" ? (
        <>
          <Input
            value={name}
            setValue={setName}
            label="Name"
            style={{
              marginBottom: "24px",
            }}
          />
          <Button
            icon="add"
            onClick={() =>
              postSchema({
                body: {
                  name,
                },
                then: reset,
              })
            }
          >
            Add a new register
          </Button>
        </>
      ) : null}
    </div>
  );
};

export const DataPage = ({ session }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loading, reset } = useFetch(
    `${process.env.REACT_APP_API}/schemas`
  );
  const [schema, setSchema] = React.useState({
    name: searchParams.get("schema"),
  });

  React.useEffect(() => {
    const newSearchParams = {
      ...searchParams,
    };
    if (schema.name) newSearchParams.schema = schema.name;

    setSearchParams(newSearchParams);
  }, [schema, searchParams, setSearchParams]);

  React.useEffect(() => {
    if (!data) return;

    const foundIndex = data.findIndex((datum) => datum.name === schema.name);
    if (foundIndex > -1) setSchema(data[foundIndex]);
  }, [data, schema]);

  const postSchema = useAPI("/schemas", "POST");

  const dropdownOptions = data?.map((datum) => {
    return {
      ...datum,
      label: datum.name,
    };
  });

  if (data && data.length === 0) {
    return <NoSchema {...{ postSchema, reset, session }} />;
  }

  const tabs = [
    {
      name: "Records",
      element:
        data && !loading && schema._id ? (
          <DocumentPage
            {...{
              schema,
            }}
            schemas={data}
          />
        ) : null,
    },
  ];

  if (schema.editable) {
    tabs.unshift({
      name: "Schema",
      element:
        data && !loading && schema._id ? (
          <SchemaPage
            {...{
              schema,
              setSchema,
              reset,
            }}
            schemas={data}
          />
        ) : null,
    });
  }

  return (
    <TabGroup
      variant="horizontal"
      minimizable={true}
      tabHeader={
        loading || !data ? (
          <Heading>{schema.name}</Heading>
        ) : (
          <Dropdown
            variant="heading"
            options={dropdownOptions}
            findActive={(opt, active) => active.name === opt.name}
            active={schema}
            setActive={setSchema}
            customDropdown={
              <Array
                elements={dropdownOptions
                  .filter((opt) => opt.name !== schema.name)
                  .map((opt) => {
                    return {
                      ...opt,
                      onClick: setSchema,
                    };
                  })}
                interactive={true}
                appendable={true}
                onAppend={(newName) => {
                  postSchema({
                    body: {
                      name: newName,
                    },
                    then: reset,
                  });
                }}
              />
            }
          />
        )
      }
      tabs={tabs}
      style={{
        height: "100%",
      }}
    />
  );
};
