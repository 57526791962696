import React from "react";

import "./RoleEditorPage.css";
import { Button, Dropdown } from "../../components";
import { useFetch } from "../../hooks";

const Schemas = ({ permissions, setPermissions }) => {
  const { data, loading } = useFetch(`${process.env.REACT_APP_API}/schemas`);

  const scopeHierarchy = [
    "NONE",
    "VIEW_DOCUMENTS",
    "EDIT_DOCUMENTS",
    "EDIT_SCHEMAS",
  ];
  const scopeLabels = {
    NONE: "cannot view register",
    VIEW_DOCUMENTS: "can view records",
    EDIT_DOCUMENTS: "can manage records",
    EDIT_SCHEMAS: "can manage register schema",
  };

  if (data && !loading) {
    data.forEach((schema) => {
      schema.maxPermission = 0;

      for (let i = scopeHierarchy.length - 1; i > 0; i--) {
        if (permissions[scopeHierarchy[i]].includes(schema._id)) {
          schema.maxPermission = i;
          break;
        }
      }
    });
  }

  const setMappedPermission = (id, newScope) => {
    const newScopeLevel = scopeHierarchy.findIndex(
      (scope) => scope === newScope
    );

    if (newScopeLevel < 0) return;

    const newPermissions = Object.keys(permissions).reduce(
      (newPermissions, scope) => {
        newPermissions[scope] = permissions[scope].filter(
          (schemaId) => schemaId !== id
        );
        return newPermissions;
      },
      {}
    );

    scopeHierarchy.slice(1, newScopeLevel + 1).forEach((scope, i) => {
      newPermissions[scope].push(id);
    });

    setPermissions(newPermissions);
  };

  return (
    <div className="roleeditor-schemas">
      {data &&
        !loading &&
        data.map((schema, i) => (
          <Dropdown
            key={i}
            active={scopeHierarchy[schema.maxPermission]}
            options={scopeHierarchy}
            getLabel={(opt) => scopeLabels[opt]}
            label={schema.name}
            setActive={(newScope) => setMappedPermission(schema._id, newScope)}
          />
        ))}
    </div>
  );
};

export const RoleEditorPage = ({ role, editRole, deleteRole, reset }) => {
  const [permissions, setPermissions] = React.useState(role.permissions);
  const [edited, setEdited] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setSaving(false);
    setEdited(false);
    setPermissions(role.permissions);
  }, [role]);

  return (
    <div className="roleeditor">
      <Schemas
        {...{ permissions }}
        setPermissions={(newPermissions) => {
          setEdited(true);
          setPermissions(newPermissions);
        }}
      />
      <div className="roleeditor-buttons">
        <Button
          icon={saving ? "delete" : "add"}
          disabled={!edited}
          onClick={() => {
            setSaving(true);
            editRole({
              body: {
                permissions,
              },
              query: {
                id: role._id,
              },
              then: reset,
            });
          }}
        >
          Save
        </Button>
        <Button
          icon="close"
          onClick={() =>
            deleteRole({
              query: {
                id: role._id,
                then: reset,
              },
            })
          }
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
