import React from "react";

import "./UserManagementPage.css";
import { Button, Dropdown, Input, TabGroup } from "../../components";
import { useAPI, useFetch } from "../../hooks";

const UserEditor = ({ user, roles, editUser, deleteUser, reset }) => {
  const [username, setUsername] = React.useState("");
  const [role, setRole] = React.useState("");

  const [edited, setEdited] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setUsername(user.username);
    setRole(user.role?._id || roles[0]._id);
  }, [roles, user]);

  React.useEffect(() => {
    setSaving(false);
    setEdited(false);
  }, [user]);

  return (
    <div className="usereditor">
      <Input
        label="Username"
        value={username}
        setValue={(newValue) => {
          setEdited(true);
          setUsername(newValue);
        }}
        style={{
          marginBottom: "24px",
          width: "100%",
        }}
      />
      <Dropdown
        label="Role"
        active={role}
        setActive={(newRole) => {
          if (newRole) {
            setEdited(true);
            setRole(newRole._id);
          }
        }}
        options={roles || []}
        getLabel={(opt) => opt && opt.name}
        findActive={(opt, active) => active === opt._id}
        style={{
          marginBottom: "24px",
        }}
      />
      <div className="usereditor-buttons">
        <Button
          icon={saving ? "delete" : "add"}
          disabled={!edited}
          onClick={() => {
            setSaving(true);
            editUser({
              body: {
                username,
                role,
              },
              query: {
                id: user._id,
              },
              then: reset,
            });
          }}
        >
          Save
        </Button>
        <Button
          icon="close"
          onClick={() =>
            deleteUser({
              query: {
                id: role._id,
                then: reset,
              },
            })
          }
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export const UserManagementPage = () => {
  const roles = useFetch(`${process.env.REACT_APP_API}/roles`);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [role, setRole] = React.useState("");

  React.useEffect(() => {
    if (roles.loading || !roles.data) return;

    setRole(roles.data[0]._id);
  }, [roles.data, roles.loading]);

  const { data, loading, reset } = useFetch(
    `${process.env.REACT_APP_API}/users`
  );
  const putUser = useAPI(`/users`, "PUT");
  const postUser = useAPI(`/users`, "POST");
  const deleteUser = useAPI(`/users`, "DELETE");

  return (
    <TabGroup
      variant={window.innerWidth < 1000 ? "horizontal" : "vertical"}
      tabHeader={
        <div className="usermanagement-userform">
          <Input
            label="Username"
            value={username}
            setValue={setUsername}
            onSubmit={() => {
              postUser({
                body: { username, password, role },
                then: reset,
              });
            }}
            style={{
              marginBottom: "24px",
              marginRight: "24px",
            }}
          />
          <Input
            label="Password"
            value={password}
            setValue={setPassword}
            onSubmit={() => {
              postUser({
                body: { username, password, role },
                then: reset,
              });
            }}
            style={{
              marginBottom: "24px",
              marginRight: "24px",
            }}
          />
          <Dropdown
            label="Role"
            active={role}
            setActive={(newRole) => {
              if (newRole) setRole(newRole._id);
            }}
            options={roles.data || []}
            getLabel={(opt) => opt && opt.name}
            findActive={(opt, active) => active === opt._id}
            style={{
              marginBottom: "24px",
              marginRight: "24px",
            }}
          />
          <Button
            icon="add"
            onClick={() => {
              postUser({
                body: { username, password, role },
                then: reset,
              });
            }}
          >
            Add new user
          </Button>
        </div>
      }
      tabs={
        data
          ? data
              .filter((datum) => !datum.admin)
              .map((datum, i) => {
                return {
                  name: datum.username,
                  element: data && !loading && (
                    <UserEditor
                      {...{ deleteUser, reset }}
                      key={i}
                      user={datum}
                      editUser={putUser}
                      roles={roles.data}
                    />
                  ),
                };
              })
          : []
      }
    />
  );
};
