import React from "react";

import "./RolePage.css";
import { Button, Input, TabGroup } from "../../components";
import { useAPI, useFetch } from "../../hooks";
import { RoleEditorPage } from "..";

export const RolePage = () => {
  const [name, setName] = React.useState("");

  const { data, loading, reset } = useFetch(
    `${process.env.REACT_APP_API}/roles`
  );
  const putRole = useAPI(`/roles`, "PUT");
  const postRole = useAPI(`/roles`, "POST");
  const deleteRole = useAPI(`/roles`, "DELETE");

  return (
    <TabGroup
      variant={window.innerWidth < 1000 ? "horizontal" : "vertical"}
      tabHeader={
        <>
          <Input
            label="Name"
            value={name}
            setValue={setName}
            onSubmit={(newName) => {
              postRole({
                body: {
                  name: newName,
                },
                then: reset,
              });
            }}
            style={{
              marginBottom: "24px",
            }}
          />
          <Button
            icon="add"
            onClick={() => {
              postRole({
                body: { name },
                then: reset,
              });
            }}
          >
            Add new role
          </Button>
        </>
      }
      tabs={
        data
          ? data.map((datum, i) => {
              return {
                name: datum.name,
                element: data && !loading && (
                  <RoleEditorPage
                    {...{ deleteRole, reset }}
                    key={i}
                    role={datum}
                    editRole={putRole}
                  />
                ),
              };
            })
          : []
      }
    />
  );
};
