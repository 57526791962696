import "./Icon.css";

import { ReactComponent as Add } from "./img/Add.svg";
import { ReactComponent as Close } from "./img/Close.svg";
import { ReactComponent as Delete } from "./img/Delete.svg";
import { ReactComponent as Dropdown } from "./img/Dropdown.svg";

export const Icon = ({
  variant = "add",
  color = "light",
  size = "sm",
  style,
}) => {
  return (
    <div className={`icon color--${color} icon-size--${size}`} {...{ style }}>
      {(() => {
        switch (variant) {
          case "add":
            return <Add className="icon-img" />;
          case "close":
            return <Close className="icon-img" />;
          case "delete":
            return <Delete className="icon-img" />;
          case "dropdown":
            return <Dropdown className="icon-img" />;
          default:
            return <Add className="icon-img" />;
        }
      })()}
    </div>
  );
};
