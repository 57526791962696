import React from "react";

import "./Heading.css";

export const Heading = ({
  tag = "h1",
  variant = "h1",
  color = "light",
  className,
  style,
  children,
}) => {
  const HeadingTag = tag;

  return (
    <HeadingTag
      className={`heading-variant--${variant} color--${color} ${
        className ? className : ""
      }`}
      style={style}
    >
      {children}
    </HeadingTag>
  );
};
