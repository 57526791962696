import React from "react";
import pluralize from "pluralize";

import "./DocumentPage.css";
import { Button, TabGroup } from "../../components";
import { ObjectEditorPage } from "..";
import { useAPI, useFetch } from "../../hooks";

export const DocumentPage = ({ schema, schemas }) => {
  const postDocument = useAPI(`/documents`, "POST");
  const putDocument = useAPI(`/documents`, "PUT");
  const deleteDocument = useAPI(`/documents/`, "DELETE");
  const { data, loading, reset } = useFetch(
    `${process.env.REACT_APP_API}/documents?schemaId=${schema._id}`
  );
  const [documents, setDocuments] = React.useState(data);

  React.useEffect(() => {
    setDocuments([]);
  }, [schema]);

  React.useEffect(() => {
    if (loading) return;

    setDocuments(data);
  }, [data, loading]);

  return (
    <TabGroup
      variant={window.innerWidth < 1000 ? "horizontal" : "vertical"}
      tabHeader={
        documents?.length === 0 ||
        documents?.some((document) => document.editable) ? (
          <Button
            icon="add"
            onClick={() => {
              postDocument({
                query: {
                  schemaId: schema._id,
                },
                then: reset,
                catch: reset,
              });
            }}
          >
            Add new {pluralize.singular(schema.name)}
          </Button>
        ) : null
      }
      tabs={
        documents
          ? documents.map((document) => {
              return {
                name: document.data.Name,
                element: (
                  <ObjectEditorPage
                    {...{ schemas }}
                    schemaObject={schema.fields}
                    object={document.data}
                    externalObject={document}
                    setParentState={(newState) => {
                      putDocument({
                        body: newState,
                        query: {
                          schemaId: schema._id,
                          id: document._id,
                        },
                        then: reset,
                        catch: reset,
                      });
                    }}
                    deleteObject={() => {
                      deleteDocument({
                        query: {
                          schemaId: schema._id,
                          id: document._id,
                        },
                        then: reset,
                        catch: reset,
                      });
                    }}
                  />
                ),
              };
            })
          : []
      }
    />
  );
};
