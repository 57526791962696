import React from "react";

export const useFetch = (
  url,
  options = {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }
) => {
  const [state, setState] = React.useState({
    data: null,
    loading: false,
    error: null,
  });
  const [reset, setReset] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      setState({
        data: null,
        loading: true,
        error: null,
      });

      try {
        const response = await fetch(url, options);
        const json = await response.json();

        setState({
          data: json,
          loading: false,
          error: null,
        });
      } catch (error) {
        setState({
          data: null,
          loading: false,
          error,
        });
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, reset]);

  return {
    ...state,
    reset: () => setReset(!reset),
  };
};
