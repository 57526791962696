import React from "react";

import "./LoginPage.css";
import { Button, Heading, Input, Text } from "../../components";

export const LoginPage = ({ authenticate, error }) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  return (
    <div className="login">
      <form
        className="login-form"
        onSubmit={(e) => {
          e.preventDefault();
          authenticate({
            username,
            password,
          });
        }}
      >
        <Heading>Log in</Heading>
        {error ? (
          <Text variant="error">
            Your username or password was incorrect. Please try again.
          </Text>
        ) : null}
        <Input
          value={username}
          setValue={setUsername}
          autocomplete="username"
          label="Username"
          required={true}
        />
        <Input
          value={password}
          setValue={setPassword}
          autocomplete="current-password"
          label="Password"
          required={true}
          type="password"
        />
        <Button>Login</Button>
      </form>
    </div>
  );
};
