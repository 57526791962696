import React from "react";

import "./Text.css";

export const Text = ({
  variant = "prose",
  color = "light",
  className,
  style,
  children,
}) => {
  return (
    <p
      {...{ style }}
      className={`text text-variant--${variant} color--${color} ${
        className ? className : ""
      }`}
    >
      {children}
    </p>
  );
};
