import React from "react";

import "./UserPage.css";
import { Heading, TabGroup } from "../../components";
import { RolePage, UserManagementPage } from "..";

export const UserPage = () => {
  const tabs = [
    {
      name: "Roles",
      element: <RolePage />,
    },
    {
      name: "Users",
      element: <UserManagementPage />,
    },
  ];

  return (
    <TabGroup
      variant="horizontal"
      minimizable={true}
      tabHeader={<Heading>Permissions</Heading>}
      tabs={tabs}
      style={{
        height: "100%",
      }}
    />
  );
};
