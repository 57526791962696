import React from "react";
import pluralize from "pluralize";

import {
  Array as ComponentArray,
  Button,
  Dropdown as ComponentDropdown,
  Input,
  ObjectViewer,
} from "../../components";
import { useCode, useFetch } from "../../hooks";

import "./DocumentViewerPage.css";

const removeOneKey = (obj, removedKey) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (key === removedKey) return;
    newObj[key] = obj[key];
  });
  return newObj;
};

const String = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [value, setValue] = React.useState(Value || "");
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );

  React.useEffect(() => setValue(Value || ""), [Value]);

  if (Conditional && !visible) return null;

  return (
    <Input
      {...{ disabled, value }}
      label={Name}
      required={Required === "Yes"}
      type="text"
      setValue={(newValue) => {
        setValue(newValue);
        setParentValue(newValue);
      }}
    />
  );
};

const Number = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [value, setValue] = React.useState(Value || "");
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );

  React.useEffect(() => setValue(Value || ""), [Value]);

  if (Conditional && !visible) return null;

  return (
    <Input
      {...{ disabled, value }}
      label={Name}
      type="number"
      required={Required === "Yes"}
      setValue={(newValue) => {
        setValue(parseInt(newValue));
        setParentValue(parseInt(newValue));
      }}
    />
  );
};

const Code = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [value, setValue] = React.useState(Value || "");
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );

  React.useEffect(() => setValue(Value || ""), [Value]);

  if (Conditional && !visible) return null;

  return (
    <Input
      {...{ disabled, value }}
      label={Name}
      required={Required === "Yes"}
      type="text"
      setValue={(newValue) => {
        setValue(newValue);
        setParentValue(newValue);
      }}
      className="font-family--monospace"
    />
  );
};

const Dropdown = ({
  Name,
  Conditional,
  Required,
  Options,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [active, setActive] = React.useState(Value || "");
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );

  React.useEffect(() => setActive(Value || ""), [Value]);

  if (Conditional && !visible) return null;

  const hasOtherValue = active && !Options.includes(active);

  return (
    <ComponentDropdown
      active={hasOtherValue ? Value : active}
      disabled={hasOtherValue || disabled}
      label={Name}
      required={Required === "Yes"}
      type="text"
      options={Options}
      getLabel={(opt) => opt}
      setActive={(newActive) => {
        setActive(newActive);
        setParentValue(newActive);
      }}
    />
  );
};

const Array = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [elements, setElements] = React.useState(Value || []);
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );

  React.useEffect(() => setElements(Value || []), [Value]);

  if (Conditional && !visible) return null;

  return (
    <ComponentArray
      {...{ disabled }}
      label={Name}
      icon="delete"
      required={Required === "Yes"}
      elements={elements.map((element, i) => {
        return {
          label: element,
          onClick: () => {
            const newElements = [...elements];
            newElements.splice(i, 1);
            setElements(newElements);
            setParentValue(newElements);
          },
        };
      })}
      setElements={(newElements) => {
        setElements(newElements);
        setParentValue(newElements);
      }}
      interactive={true}
      appendable={true}
      onAppend={(newElement) => {
        const newElements = [...elements];
        newElements.push(newElement);
        setElements(newElements);
        setParentValue(newElements);
      }}
    />
  );
};

const Computed = ({ Name, Conditional, Computation, state }) => {
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );
  const value = useCode(
    Computation,
    state,
    JSON.stringify(removeOneKey(state, Name))
  );

  if (Conditional && !visible) return null;

  return (
    <Input
      value={value || "Computing..."}
      label={`${Name} (Computed)`}
      disabled={true}
      type="text"
      setValue={() => {}}
    />
  );
};

const Register = ({
  Name,
  Conditional,
  Multiplicity,
  Required,
  Fields,
  Value,
  disabled,
  schemas,
  setParentValue,
  state,
}) => {
  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API}/documents?schemaId=${
      schemas.find((schema) => schema.name === Name)?._id
    }`
  );
  const [options, setOptions] = React.useState([]);
  const [active, setActive] = React.useState(Value || "");
  const visible = useCode(
    Conditional,
    state,
    JSON.stringify(removeOneKey(state, Register))
  );

  React.useEffect(() => {
    if (loading) return;

    const newData = data
      ? [...data].map((d) => {
          const newDatum = d.data;
          newDatum._id = d._id;
          return newDatum;
        })
      : [];
    if (Required !== "Yes")
      newData.unshift({
        Name: "Unset",
        _id: "",
      });
    setOptions(newData);
  }, [data, loading, Required]);
  React.useEffect(() => setActive(Value || ""), [Value]);

  if (Conditional && !visible) return null;

  const activeObject = options.find((opt) => opt._id === active);
  const fields = Fields && Fields.length > 0 ? Fields : ["Name"];

  return (
    <>
      <ComponentDropdown
        {...{ disabled }}
        active={activeObject?.Name}
        options={options.map((opt) => opt.Name)}
        label={Multiplicity === "Single" ? pluralize.singular(Name) : Name}
        required={Required === "Yes"}
        getLabel={(opt) => opt}
        type="text"
        setActive={(newActive) => {
          const activeId = options.find((opt) => opt.Name === newActive)?._id;
          setActive(activeId);
          setParentValue(activeId);
        }}
      />
      <ObjectViewer {...{ fields }} disabled={true} object={activeObject} />
    </>
  );
};

const Fields = {
  String,
  Number,
  Code,
  Dropdown,
  Array,
  Computed,
  Register,
};

export const DocumentViewerPage = ({
  color = "light",
  schemaObject,
  schemas,
  object,
  externalObject,
  setParentState,
  deleteObject,
}) => {
  const [state, setState] = React.useState(
    schemaObject.reduce((formObject, field) => {
      formObject[field.Name] = object[field.Name];
      return formObject;
    }, {})
  );
  const objectString = JSON.stringify(object);

  React.useEffect(() => {
    setState(
      schemaObject.reduce((formObject, field) => {
        formObject[field.Name] = object[field.Name];
        return formObject;
      }, {})
    );
  }, [objectString, object, schemaObject]);

  return (
    <div className={`objecteditor color--${color}`}>
      {schemaObject.map((field, i) => {
        const Field = Fields[field.Type];

        return (
          <Field
            key={i}
            {...{ ...field, state, schemas }}
            Value={state[field.Name]}
            setParentValue={(newValue) => {
              const newState = { ...state };
              newState[field.Name] = newValue;
              setState(newState);
            }}
            disabled={!externalObject.editable}
          />
        );
      })}
      {externalObject.External
        ? (() => {
            const externalMap = new Map();

            externalObject.External.forEach((obj) => {
              if (externalMap.has(obj.Schema)) {
                externalMap.get(obj.Schema).push(obj);
              } else {
                externalMap.set(obj.Schema, [obj]);
              }
            });

            const nodes = [];

            externalMap.forEach((externals, schema) => {
              nodes.push(
                <ComponentArray
                  label={schema}
                  key={schema}
                  disabled={true}
                  type="text"
                  elements={externals.map((e) => e.Name)}
                />
              );
            });

            return nodes;
          })()
        : null}
      {externalObject.editable ? (
        <div className="objecteditor-buttons">
          <Button icon="add" onClick={() => setParentState(state)}>
            Save
          </Button>
          <Button icon="close" onClick={deleteObject}>
            Delete
          </Button>
        </div>
      ) : null}
    </div>
  );
};
