import React from "react";
import { Array, Button, Heading, Icon, Text } from "..";

import "./Dropdown.css";

export const Dropdown = ({
  variant = "button",
  color = "light",
  options,
  active,
  disabled,
  required,
  findActive = (opt, active) => opt === active,
  getLabel = (opt) => opt?.label,
  label,
  setActive,
  customDropdown,
  className,
  style,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(options.length === 0);
  const dropdownRef = React.useRef();

  const activeIndex = options.findIndex((opt) => findActive(opt, active));

  React.useEffect(() => setIsEmpty(options.length === 0), [options]);

  React.useEffect(() => {
    if (!isEmpty && !disabled && activeIndex === -1) {
      setActive(options[0]);
    }
  }, [activeIndex, options, isEmpty, disabled, setActive]);

  React.useEffect(() => {
    setVisible(false);
  }, [active]);

  const Trigger = (() => {
    switch (variant) {
      case "button":
        return (
          <Button
            color={color}
            icon="dropdown"
            disabled={isEmpty || disabled}
            style={{
              borderBottomLeftRadius: visible ? 0 : undefined,
              width: "100%",
            }}
          >
            {isEmpty
              ? "No options"
              : disabled
              ? getLabel(active)
              : getLabel(options[activeIndex])}
          </Button>
        );
      case "heading":
        return (
          <div
            style={{
              display: "inline-grid",
              gridTemplateColumns: "auto 1fr",
              gridGap: "16px",
              alignItems: "center",
            }}
          >
            <Heading color={color}>
              {isEmpty ? "No options" : getLabel(options[activeIndex])}
            </Heading>
            <Icon variant="dropdown" style={{ marginTop: "8px" }} />
          </div>
        );
      case "text":
        return (
          <div
            style={{
              display: "inline-grid",
              gridTemplateColumns: "auto 1fr",
              gridGap: "16px",
              alignItems: "center",
            }}
          >
            <Text color={color}>
              {isEmpty ? "No options" : getLabel(options[activeIndex])}
            </Text>
            <Icon variant="dropdown" style={{ marginTop: "8px" }} />
          </div>
        );
      default:
        return <>TODO</>;
    }
  })();

  React.useEffect(() => {
    const hideOnExternalClick = (event) => {
      if (visible && !dropdownRef.current?.contains(event.target))
        setVisible(false);
    };

    document.body.addEventListener("click", hideOnExternalClick);

    return () =>
      document.body.removeEventListener("click", hideOnExternalClick);
  }, [visible, setVisible, dropdownRef]);

  return (
    <div
      className={`dropdown dropdown-visible--${visible} dropdown-variant--${variant} color--${color} ${
        className ? className : ""
      }`}
      style={style}
    >
      <div
        className={`dropdown-trigger dropdown-trigger-disabled--${
          isEmpty || disabled
        }`}
        onClick={isEmpty || disabled ? () => {} : () => setVisible(true)}
      >
        {Trigger}
        {label ? (
          <div className="dropdown-trigger--label">
            <Text variant="label">
              {label}
              {required ? " *" : ""}
            </Text>
          </div>
        ) : null}
      </div>
      <div
        className={`dropdown-dropdown dropdown-dropdown-visible--${visible}`}
        ref={dropdownRef}
      >
        {customDropdown ? (
          customDropdown
        ) : (
          <Array
            elements={options
              .filter((opt) => !findActive(opt, active))
              .map((opt) => {
                return {
                  ...opt,
                  label: getLabel(opt),
                  onClick: () => setActive(opt),
                };
              })}
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            interactive={true}
          />
        )}
      </div>
    </div>
  );
};
