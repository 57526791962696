import React from "react";
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
} from "react-router-dom";

import "./TabGroup.css";
import { Button } from "..";

const StatelessTabGroup = ({
  variant,
  color,
  minimizable,
  tabs,
  tablistMapper,
  tabpaneElement,
  tabHeader,
  className,
  style,
}) => {
  const [minimized, setMinimized] = React.useState(false);

  return (
    <div
      className={`tabgroup tabgroup-minimized--${minimized} tabgroup-variant--${variant} color--${color} ${
        className ? className : ""
      }`}
      style={style}
    >
      <div className="tabgroup-tabs">
        {tabHeader ? (
          <div className="tabgroup-tabs--tabheader">{tabHeader}</div>
        ) : null}
        <div className="tabgroup-tabs--tablist">{tabs.map(tablistMapper)}</div>
        {minimizable ? (
          <Button
            style={{ zIndex: 998 }}
            variant="icon"
            size={window.innerWidth < 1000 ? "xxxs" : "xs"}
            icon="delete"
            className="tabgroup-tabs--minimizer"
            onClick={() => setMinimized(!minimized)}
          ></Button>
        ) : null}
      </div>
      <div className="tabgroup-pane">{tabpaneElement}</div>
    </div>
  );
};

export const TabGroup = ({
  variant = "vertical",
  color = "light",
  minimizable,
  tabs,
  tabHeader,
  className,
  style,
}) => {
  const [activeTab, setActiveTab] = React.useState(0);

  React.useEffect(() => {
    if (activeTab < 0 || activeTab >= tabs.length) {
      setActiveTab(Math.max(0, Math.min(tabs.length - 1, activeTab)));
    }
  }, [activeTab, tabs.length]);

  return (
    <StatelessTabGroup
      {...{
        variant,
        color,
        minimizable,
        tabs,
        tabHeader,
        className,
        style,
      }}
      tablistMapper={(tab, i) => (
        <Button
          key={i}
          active={i === activeTab}
          onClick={() => setActiveTab(i)}
        >
          {tab.name}
        </Button>
      )}
      tabpaneElement={tabs.length > activeTab ? tabs[activeTab].element : null}
    />
  );
};

export const RoutedTabGroup = ({
  variant = "vertical",
  color = "light",
  minimizable,
  tabs,
  tabHeader,
  className,
  style,
}) => {
  const { pathname } = useLocation();

  return (
    <StatelessTabGroup
      {...{
        variant,
        color,
        minimizable,
        tabs,
        tabHeader,
        className,
        style,
      }}
      tablistMapper={(tab, i) => (
        <Button
          key={i}
          active={matchPath(tab.route, pathname)}
          href={tab.route}
        >
          {tab.name}
        </Button>
      )}
      tabpaneElement={
        <Routes>
          {tabs.map((tab, i) => (
            <Route key={i} path={tab.route} element={tab.element} />
          ))}
          {tabs.length > 0 ? (
            <Route path="*" element={<Navigate to={tabs[0].route} />} />
          ) : null}
        </Routes>
      }
    />
  );
};
