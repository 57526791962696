import React from "react";

import "./Input.css";
import { Icon, Text } from "..";

export const Input = ({
  variant = "primary",
  color = "light",
  autocomplete,
  icon,
  label,
  type = "text",
  value,
  disabled,
  required,
  setValue,
  onSubmit,
  className,
  style,
}) => {
  const [active, setActive] = React.useState(("" + value).length > 0);
  const [caretPosition, setCaretPosition] = React.useState(0);
  const inputRef = React.useRef();

  const canDoSelection = ["text", "search", "url", "tel", "password"].includes(
    type
  );
  const setCaret = (el, position) => {
    let range;

    if (el.createTextRange) {
      range = el.createTextRange();
      range.move("character", position);
      range.select();
    } else {
      if (el.selectionStart !== undefined) {
        el.setSelectionRange(position, position);
      }
    }
  };

  React.useEffect(() => {
    setActive(("" + value).length > 0);

    if (!canDoSelection) return;
    setCaretPosition(caretPosition);
    setCaret(inputRef.current, caretPosition);
  }, [value, setCaretPosition, caretPosition, canDoSelection]);

  return (
    <label
      {...{
        style,
      }}
      className={`input input-variant--${variant} input-active--${active} color--${color} ${
        icon ? "input-withicon" : ""
      } ${className ? className : ""}`}
    >
      <div className="input-label">
        <Text variant={active ? "label" : "prose"}>
          {label}
          {required ? " *" : ""}
        </Text>
      </div>
      <div className="input-input">
        <input
          {...{
            disabled,
            required,
            value,
            onSubmit,
            type,
          }}
          autoComplete={autocomplete}
          ref={inputRef}
          onFocus={() => setActive(true)}
          onBlur={(e) => {
            if (e.target.value.length === 0) setActive(false);
          }}
          onChange={(e) => {
            const target = e.target;
            if (target.selectionStart || target.selectionStart === 0) {
              setCaretPosition(target.selectionStart);
            }

            setValue(target.value);
            if (target.value.length === 0) setActive(false);
            else setActive(true);
          }}
          onClick={(e) => {
            const target = e.target;
            if (target.selectionStart || target.selectionStart === 0) {
              setCaretPosition(target.selectionStart);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && onSubmit != null) {
              e.preventDefault();
              e.target.blur();
              onSubmit(e.target.value);
            }
          }}
          style={{
            paddingRight: icon ? "64px" : "",
          }}
        />
      </div>
      <div className="input-icon" onClick={() => onSubmit(value)}>
        {icon ? <Icon variant={icon} size="xs" /> : null}
      </div>
    </label>
  );
};
