import React from "react";

export const useAPI = (endpoint, method) => {
  const [params, setParams] = React.useState(undefined);
  const [trigger, setTrigger] = React.useState(false);

  React.useEffect(() => {
    if (!params) return;

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API}${endpoint}${
            params.query
              ? "?" +
                Object.keys(params.query)
                  .map((param) => `${param}=${params.query[param]}`)
                  .join("&")
              : ""
          }`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(params.body),
            method,
          }
        );
        if (!response.ok)
          throw new Error(`Response failed with status ${response.status}`);

        const data = await response.json();
        if (params.then) params.then(data);
      } catch (error) {
        console.error(error);
        if (params.catch) params.catch(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (newParams) => {
    setParams(newParams);
    setTrigger(!trigger);
  };
};
