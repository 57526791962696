import React from "react";

import "./Array.css";
import { Icon, Input, Text } from "..";

export const Array = ({
  color = "light",
  disabled,
  required,
  label,
  interactive,
  icon,
  appendable = false,
  onAppend,
  elements,
  getLabel = (element, interactive) => (interactive ? element.label : element),
  className,
  style,
}) => {
  const [value, setValue] = React.useState("");

  return (
    <div
      {...{ style }}
      className={`array array-interactive--${interactive} ${
        icon ? "array-withicon" : ""
      } array-appendable--${appendable} array-disabled--${disabled} color--${color} ${
        className ? className : ""
      }`}
    >
      {label ? (
        <div className="array-label">
          <Text variant="label">
            {label}
            {required ? " *" : ""}
          </Text>
        </div>
      ) : null}
      {elements.map((element, i) => (
        <div
          key={i}
          className="array-element"
          onClick={() => (interactive ? element.onClick(element) : undefined)}
        >
          <Text style={{ margin: "0" }}>{getLabel(element, interactive)}</Text>
          {icon ? (
            <div className="array-element--icon">
              <Icon variant={icon} size="xs" />
            </div>
          ) : null}
        </div>
      ))}
      {appendable ? (
        <div className="array-element--input">
          <Input
            label={`Add ${label ? `to ${label}` : "another"}`}
            icon="add"
            {...{
              value,
              setValue,
            }}
            onSubmit={
              value.length > 0
                ? (newValue) => {
                    onAppend(newValue);
                    setValue("");
                  }
                : () => {}
            }
          />
        </div>
      ) : null}
    </div>
  );
};
