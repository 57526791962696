import React from "react";
import pluralize from "pluralize";

import {
  Array as ComponentArray,
  Button,
  Dropdown as ComponentDropdown,
  Input,
  Textarea,
} from "../../components";
import { useCode, useFetch } from "../../hooks";

import "./ObjectEditorPage.css";

const removeOneKey = (obj, removedKey) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (key === removedKey) return;
    newObj[key] = obj[key];
  });
  return newObj;
};

const hoistExternal = (object) => {
  const codeObject = { ...object };

  if (codeObject?.External) {
    Object.keys(codeObject.External).forEach(
      (register) => (codeObject[register] = codeObject.External[register])
    );
  }

  return codeObject;
};

const String = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [value, setValue] = React.useState(Value || "");

  React.useEffect(() => setValue(Value || ""), [Value]);

  return (
    <Input
      {...{ disabled, value }}
      label={Name}
      required={Required === "Yes"}
      type="text"
      setValue={(newValue) => {
        setParentValue(newValue);
      }}
    />
  );
};

const Number = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [value, setValue] = React.useState("" + (Value || 0));

  React.useEffect(() => setValue("" + (Value || 0)), [Value]);

  return (
    <String
      {...{
        Name,
        Conditional,
        Required,
        disabled,
        state,
      }}
      Value={value}
      setParentValue={(newValue) => {
        setParentValue(parseInt(newValue));
      }}
    />
  );
};

const Date = String;

const Code = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [value, setValue] = React.useState(Value || "");

  React.useEffect(() => setValue(Value || ""), [Value]);

  return (
    <Textarea
      {...{ disabled, value }}
      label={Name}
      required={Required === "Yes"}
      type="text"
      setValue={(newValue) => {
        setParentValue(newValue);
      }}
      className="font-family--monospace"
    />
  );
};

const Dropdown = ({
  Name,
  Conditional,
  Dynamic,
  Required,
  Options,
  Value,
  allowExternalValues,
  disabled,
  setParentValue,
  state,
}) => {
  const codeObject = hoistExternal(state);

  const computedOptions = useCode(
    Dynamic === "Yes" ? Options : "",
    codeObject,
    JSON.stringify(removeOneKey(state, Name))
  );
  const optionQuaternary = (
    dynamicAndReady,
    dynamicAndUnready,
    dynamicAndError,
    notDynamic
  ) => {
    if (Dynamic === "Yes") {
      if (computedOptions) {
        return computedOptions === "Error!" ||
          computedOptions.some((option) => !option)
          ? dynamicAndError
          : dynamicAndReady;
      }

      return dynamicAndUnready;
    }

    return notDynamic;
  };

  const [active, setActive] = React.useState(Value || "");

  React.useEffect(() => setActive(Value || ""), [Value]);

  const hasOtherValue = active && !Options.includes(active);

  return (
    <ComponentDropdown
      active={
        hasOtherValue
          ? Value
          : optionQuaternary(active, "Computing...", "Error!", active)
      }
      disabled={optionQuaternary(
        false || disabled,
        true,
        true,
        (allowExternalValues && hasOtherValue) || disabled
      )}
      label={Name}
      required={Required === "Yes"}
      type="text"
      options={optionQuaternary(
        computedOptions,
        ["Computing..."],
        ["Error!"],
        Options
      )}
      getLabel={(opt) => opt}
      setActive={(newActive) => {
        setParentValue(newActive);
      }}
    />
  );
};

const Array = ({
  Name,
  Conditional,
  Required,
  Value,
  disabled,
  setParentValue,
  state,
}) => {
  const [elements, setElements] = React.useState(Value || []);

  React.useEffect(() => setElements(Value || []), [Value]);

  return (
    <ComponentArray
      {...{ disabled }}
      label={Name}
      icon="delete"
      required={Required === "Yes"}
      elements={elements.map((element, i) => {
        return {
          label: element,
          onClick: () => {
            const newElements = [...elements];
            newElements.splice(i, 1);
            setParentValue(newElements);
          },
        };
      })}
      setElements={(newElements) => {
        setParentValue(newElements);
      }}
      interactive={true}
      appendable={true}
      onAppend={(newElement) => {
        const newElements = [...elements];
        newElements.push(newElement);
        setParentValue(newElements);
      }}
    />
  );
};

const Computed = ({
  Name,
  Value,
  externalObject,
  Computation,
  setParentValue,
  state,
}) => {
  const object = { ...externalObject.data };
  object.External = state.External;
  const codeObject = hoistExternal(object);

  const value = useCode(
    Computation,
    codeObject,
    JSON.stringify(removeOneKey(state, Name))
  );

  React.useEffect(() => {
    if (value === Value || value === null || value === undefined) return;

    setParentValue(value);
  });

  return (
    <Input
      value={value || "Computing..."}
      label={`${Name} (Computed)`}
      disabled={true}
      type="text"
      setValue={() => {}}
    />
  );
};

const Register = ({
  Name,
  Conditional,
  Multiplicity,
  Required,
  Value,
  disabled,
  schemas,
  setParentValue,
  state,
}) => {
  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API}/documents?schemaId=${
      schemas.find((schema) => schema.name === Name)?._id
    }`
  );
  const [options, setOptions] = React.useState([]);
  const [active, setActive] = React.useState(Value || "");

  React.useEffect(() => {
    if (loading) return;

    const newData = data
      ? [...data].map((d) => {
          const newDatum = d.data;
          newDatum._id = d._id;
          return newDatum;
        })
      : [];
    if (Required !== "Yes")
      newData.unshift({
        Name: "Unset",
        _id: "",
      });
    setOptions(newData);
  }, [data, loading, Required]);
  React.useEffect(() => setActive(Value || ""), [Value]);

  const activeObject = options.find((opt) => opt._id === active);

  return (
    <ComponentDropdown
      {...{ disabled }}
      active={activeObject?.Name}
      options={options.map((opt) => opt.Name)}
      label={Multiplicity === "Single" ? pluralize.singular(Name) : Name}
      required={Required === "Yes"}
      getLabel={(opt) => opt}
      type="text"
      setActive={(newActive) => {
        const activeObject = options.find((opt) => opt.Name === newActive);
        const newState = { ...state };
        newState[Name] = activeObject?._id;
        newState.External = {
          [Name]: activeObject,
        };
        setParentValue(newState, true);
      }}
    />
  );
};

const Fields = {
  String,
  Number,
  Date,
  Code,
  Dropdown,
  Array,
  Computed,
  Register,
};

const TypedField = ({
  field,
  schemas,
  state,
  setState,
  setFieldVisibility,
  edited,
  setEdited,
  externalObject,
}) => {
  const Field = Fields[field.Type];

  const visible = useCode(
    field.Conditional,
    state,
    JSON.stringify(removeOneKey(state, field.Register))
  );

  React.useEffect(() => {
    setFieldVisibility(field.Name, !field.Conditional || visible);
  }, [visible, field.Name, field.Conditional, setFieldVisibility]);

  if (field.Conditional && !visible) return null;

  return (
    <Field
      {...{ ...field, state, schemas, externalObject }}
      Value={state[field.Name]}
      setParentValue={(newValue, richly) => {
        if (!edited) setEdited(true);
        if (richly) {
          setState(newValue);
        } else {
          const newState = { ...state };
          newState[field.Name] = newValue;
          setState(newState);
        }
      }}
      disabled={!externalObject.editable}
      allowExternalValues={state.Type !== "Register"}
    />
  );
};

const stateFromObject = (object, schemaObject) => {
  const state = schemaObject.reduce((formObject, field) => {
    formObject[field.Name] = object[field.Name];
    return formObject;
  }, {});
  if (object.External) state.External = object.External;
  return state;
};

export const ObjectEditorPage = ({
  schemaObject,
  schemas,
  object,
  externalObject,
  setParentState,
  deleteObject,
}) => {
  const [state, setState] = React.useState(
    stateFromObject(object, schemaObject)
  );
  const fieldVisibility = React.useRef();
  const objectString = JSON.stringify(object);
  const [edited, setEdited] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setSaving(false);
    setEdited(false);
    setState(stateFromObject(object, schemaObject));
    fieldVisibility.current = schemaObject.reduce((fieldVisibility, field) => {
      fieldVisibility[field.Name] = false;
      return fieldVisibility;
    }, {});
  }, [objectString, object, schemaObject]);

  return (
    <div className="objecteditor">
      {schemaObject.map((field, i) => (
        <TypedField
          key={i}
          {...{
            field,
            edited,
            setEdited,
            schemas,
            externalObject,
            state,
            setState,
          }}
          setFieldVisibility={(field, visible) => {
            if (
              !fieldVisibility.current ||
              !(field in fieldVisibility.current) ||
              fieldVisibility.current[field] === visible
            )
              return;
            fieldVisibility.current[field] = visible;
          }}
        />
      ))}
      {externalObject.editable && (
        <div className="objecteditor-buttons">
          <Button
            icon={saving ? "delete" : "add"}
            disabled={!edited}
            onClick={() => {
              fieldVisibility.current.Type = true;
              fieldVisibility.current.Name = true;

              const newState = Object.keys(state).reduce((newState, key) => {
                if (fieldVisibility.current[key]) newState[key] = state[key];

                return newState;
              }, {});
              setSaving(true);
              setParentState(newState);
            }}
          >
            Save
          </Button>
          <Button icon="close" onClick={deleteObject}>
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};
