import React from "react";
import { v4 as uuidv4 } from "uuid";

export const useCode = (code, args = {}, triggerRecomputation) => {
  const [message, setMessage] = React.useState(null);
  const [ready, setReady] = React.useState(false);
  const [uuid] = React.useState(uuidv4());
  const stringifiedArgs = JSON.stringify(args);

  React.useEffect(() => {
    const iframe = document.createElement("iframe");

    iframe.style.display = "none";
    iframe.setAttribute("id", `iframe-uuid-${uuid}`);
    iframe.setAttribute("sandbox", "allow-scripts");
    iframe.setAttribute(
      "srcdoc",
      `
      <!DOCTYPE html>
      <html>
        <head>
          <script>
            const messageHandler = (event) => {
              if (
                !event ||
                !event.data ||
                event.data.type !== "useCodeEvent" ||
                event.data.uuid !== "${uuid}"
              )
                return;
        
              if (event.data.data === "REMOVE") {
                window.removeEventListener("message", messageHandler);
                return;
              }
        
              const { code, args } = event.data.data;
              const newArgs = {};
              Object.keys(args).forEach(
                (key) => (newArgs[key.replace(/ |\\(|\\)|\\-|\\+|\\./g, "")] = args[key])
              );
        
              const computedFunction = new Function(\`
                "use strict";
                return (
                  function doWork(\${
                    Object.keys(newArgs).length > 0
                      ? \`{\${Object.keys(newArgs).join(",")}}, \`
                      : ""
                  }window={}, fetch={}) {\${code}}
                )\`)()
                
              try {
                const computedData = computedFunction( newArgs );
                
                window.top.postMessage({
                  type: "useCodeEvent",
                  uuid: "${uuid}",
                  data: computedData,
                }, "${window.location}");
              } catch(err) {
                window.top.postMessage({
                  type: "useCodeEvent",
                  uuid: "${uuid}",
                  data: "Error!",
                }, "${window.location}");
              }
            }
        
            window.addEventListener("message", messageHandler);
          </script>
        </head>
      </html>
    `
    );

    const messageHandler = (event) => {
      if (
        !event ||
        !event.data ||
        event.data.type !== "useCodeEvent" ||
        event.data.uuid !== uuid
      )
        return;

      setMessage(event.data?.data);
    };

    window.addEventListener("message", messageHandler);

    const readyOnLoad = () => {
      setReady(true);
    };

    iframe.addEventListener("load", readyOnLoad);

    document.body.appendChild(iframe);

    return () => {
      if (document.querySelector(`#iframe-uuid-${uuid}`).parentElement) {
        iframe.removeEventListener("load", readyOnLoad);
        document
          .querySelector(`#iframe-uuid-${uuid}`)
          .parentElement.removeChild(iframe);
      }
      window.removeEventListener("message", messageHandler);
    };
  }, [setMessage, uuid]);

  const trigger = triggerRecomputation || stringifiedArgs;

  React.useEffect(() => {
    if (!code || !ready) return;

    document.querySelector(`#iframe-uuid-${uuid}`).contentWindow.postMessage(
      {
        type: "useCodeEvent",
        uuid,
        data: {
          code,
          args,
        },
      },
      "*"
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, ready, uuid, trigger]);

  return message;
};
