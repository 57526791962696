import React from "react";

import "./Textarea.css";
import { Text } from "..";

export const Textarea = ({
  variant = "primary",
  color = "light",
  label,
  value,
  disabled,
  required,
  setValue,
  onSubmit,
  className,
  style,
}) => {
  const [active, setActive] = React.useState(("" + value).length > 0);
  const [caretPosition, setCaretPosition] = React.useState(0);
  const textareaRef = React.useRef();

  const canDoSelection = true;
  const setCaret = (el, position) => {
    let range;

    if (el.createTextRange) {
      range = el.createTextRange();
      range.move("character", position);
      range.select();
    } else {
      if (el.selectionStart !== undefined) {
        el.setSelectionRange(position, position);
      }
    }
  };

  React.useEffect(() => {
    setActive(("" + value).length > 0);

    if (!canDoSelection) return;
    setCaretPosition(caretPosition);
    setCaret(textareaRef.current, caretPosition);
  }, [value, setCaretPosition, caretPosition, canDoSelection]);

  return (
    <label
      {...{
        style,
      }}
      className={`textarea textarea-variant--${variant} textarea-active--${active} color--${color} ${
        className ? className : ""
      }`}
    >
      <div className="textarea-hidden">{value}&nbsp;</div>
      <div className="textarea-textarea">
        <textarea
          {...{
            disabled,
            required,
            value,
            onSubmit,
          }}
          ref={textareaRef}
          onFocus={() => setActive(true)}
          onBlur={(e) => {
            if (e.target.value.length === 0) setActive(false);
          }}
          onChange={(e) => {
            const target = e.target;
            if (target.selectionStart || target.selectionStart === 0) {
              setCaretPosition(target.selectionStart);
            }

            setValue(target.value);
            if (target.value.length === 0) setActive(false);
            else setActive(true);
          }}
          onClick={(e) => {
            const target = e.target;
            if (target.selectionStart || target.selectionStart === 0) {
              setCaretPosition(target.selectionStart);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && onSubmit != null) {
              e.preventDefault();
              e.target.blur();
              onSubmit(e.target.value);
            }
          }}
        />
      </div>
      <div className="textarea-label">
        <Text variant={active ? "label" : "prose"}>
          {label}
          {required ? " *" : ""}
        </Text>
      </div>
    </label>
  );
};
