import React from "react";

import "./SchemaPage.css";
import { Button, Input, TabGroup } from "../../components";
import { ObjectEditorPage } from "..";
import { useAPI, useFetch } from "../../hooks";

export const SchemaPage = ({ schema, setSchema, reset }) => {
  const [name, setName] = React.useState(schema?.name || "");

  React.useEffect(() => {
    setName(schema?.name || "");
  }, [schema]);

  const { data, loading } = useFetch(
    `${process.env.REACT_APP_API}/schemas/${schema._id}/fields`
  );
  const putSchema = useAPI(`/schemas?id=${schema._id}`, "PUT");
  const postSchemaField = useAPI(`/schemas/${schema._id}/fields`, "POST");
  const putSchemaField = useAPI(`/schemas/${schema._id}/fields`, "PUT");
  const deleteSchemaField = useAPI(`/schemas/${schema._id}/fields`, "DELETE");

  return (
    <TabGroup
      variant={window.innerWidth < 1000 ? "horizontal" : "vertical"}
      tabHeader={
        <>
          <Input
            label="Name"
            value={name}
            setValue={setName}
            onSubmit={(newName) => {
              putSchema({
                body: {
                  name: newName,
                },
                then: reset,
              });
            }}
            style={{
              marginBottom: "24px",
            }}
          />
          <Button
            icon="add"
            onClick={() => {
              const newSchema = { ...schema };
              const newField = {
                Name: `Field${schema.fields.length}`,
                Type: "String",
                Required: "No",
              };
              newSchema.fields.push(newField);
              postSchemaField({
                body: newField,
                then: () => setSchema(newSchema),
                catch: reset,
              });
            }}
          >
            Add new field
          </Button>
        </>
      }
      tabs={schema.fields.map((datum, i) => {
        return {
          name: datum.Name,
          element:
            data && !loading ? (
              <ObjectEditorPage
                schemaObject={data}
                object={datum}
                externalObject={schema}
                setParentState={(newState) => {
                  const newSchema = { ...schema };
                  newSchema.fields[i] = newState;
                  putSchemaField({
                    body: newState,
                    query: {
                      index: i,
                    },
                    then: () => setSchema(newSchema),
                    catch: reset,
                  });
                }}
                deleteObject={() => {
                  const newSchema = { ...schema };
                  newSchema.fields.splice(i, 1);
                  deleteSchemaField({
                    query: {
                      index: i,
                    },
                    then: () => setSchema(newSchema),
                    catch: reset,
                  });
                }}
              />
            ) : null,
        };
      })}
    />
  );
};
