import React from "react";
import { useLinkClickHandler } from "react-router-dom";
import { Icon } from "..";

import "./Button.css";

export const Button = ({
  variant = "primary",
  color = "light",
  size,
  icon,
  href,
  active,
  disabled,
  onClick,
  className,
  style,
  children,
}) => {
  const handleClick = useLinkClickHandler(href || "");

  return (
    <button
      {...{
        disabled,
        style,
      }}
      className={`button button-variant--${variant} button-size--${size} color--${color} ${
        icon ? "button-withicon" : ""
      } ${active ? "button-active" : ""} ${className ? className : ""}`}
      onClick={href ? handleClick : onClick}
    >
      {variant !== "icon" ? children : null}
      {icon ? <Icon variant={icon} size={size || "xs"} /> : null}
    </button>
  );
};
